/* Colors */
$red: #FC4D38;
$red-light: #ffedeb;
$navy: #15206B; /* Lo: Dark blue */
$orange: #FF9528;
$orange-light: #fff4e9;
$green-bright: #00865A;
$blue: #3856A7; /* Lo: Mid blue */
$blue-light: #B9C4E0;
$blue-lighter: #d5d9e8;
$blue-extra-light: #E1E6F2;
$blue-extra-extra-light: #EBEEF6; /* Lo: Light blue */
$blue-milestone: #e7e8f0;
$pink-dark: #f688a2;
$pink: #FD6351;
$pink-med-light: rgb(250, 182, 187);
$pink-light: #FFD9E0;
$teal-extra-light: #EBF5F0;
$beige-light: #F2E5D6;
$beige-extra-light: #FFF5E8;
$beige-milestone: #f8f5ed;
$white: #FFFFFF;
$gray: #F2F2F2;
$gray-light: #fcfcfc;
$gray-dark: #282828;
$black: #000000;
$black-light: #262626;
$mint: #C9E5DE;
$footer-pink: #F7B0C2;

/* Fonts */
$sofia: "sofia-pro", sans-serif;

/* Breakpoints */
$desktop: 1440px;
$laptop: 1280px;
$tablet: 1110px;
$tablet-vertical: 1023px;
$tablet-landscape: 900px;
$mobile: 767px;
$mobile-medium: 540px;
$mobile-small: 420px;
$mobile-tiny: 360px;
$checkout-mobile-medium: 500px;

$header_desktop_height: 60px;
$header_tablet_height: 60px;
$header_mobile_height: 50px;
$pack_scene_footer_height: 60px;
$pack_scene_footer_mobile_height: 60px;

$standard-padding: 40px;
$mobile-padding: 30px;
$bottom-padding:  120px;

$checkout-field-padding: 22px;
$mobile-checkout-field-padding: 14px;

$recommended_pack_max_width: 1300px;

/* Customize Pack grid styles */
$pouch-width: 324px;
$pouch-side-margin: 10px;
$pouch-small-width: 154px;
$pouch-small-side-margin: 8px;

$four-pouch-width: ($pouch-width * 4) + ($pouch-side-margin * 4 * 2);
$three-pouch-width: ($pouch-width * 3) + ($pouch-side-margin * 3 * 2);
$two-pouch-width: ($pouch-width * 2) + ($pouch-side-margin * 2 * 2);
$two-pouch-small-width: ($pouch-small-width * 2) + ($pouch-small-side-margin * 2 * 2);

$three-pouch-bp: ($four-pouch-width + $standard-padding);
$two-pouch-bp: ($three-pouch-width + $standard-padding);
$two-small-pouch-bp: ($two-pouch-width + $standard-padding);
$one-small-pouch-bp: ($two-pouch-small-width + $mobile-padding);
