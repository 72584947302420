.b-date-input {

  .e-input,
  .e-datepicker {
    background-color: transparent;
    border: 0;
    border-bottom: 3px solid $navy;
    color: $navy;
    padding: 0;

    &::placeholder {
      color: $blue-light;
    }

    &:focus {
      outline: none;
    }

    border-radius: 0;
    text-align: center;
    width: 100%;

    &:disabled {
      opacity: 0.5;
      cursor: progress;
    }

  }
}
