
.b-header {
  z-index: 100;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;

  .e-header-inner {
    background-color: $white;
    display: flex;
    height: $header_desktop_height;
    @include standard-padding(null, true, null, true);
    @include responsive($tablet) {
      height: $header_mobile_height;
    }
    @include responsive($tablet) {
      padding: 0;
    }
  }

  &.m-priority-index {
    z-index: 1000;
  }

  .e-nav {
    align-items: center;
    display: flex;
    margin: 0 auto;
    position: relative;
    text-align: center;
    width: 100%;
    &.e-center-logo {
      justify-content: center;
    }
    @include content-max-width();

    .e-mobile-nav-open-close-wrap {
      display: block;
      height: 100%;
      @media screen and (min-width: 900px) {
        display: none;
      }
    }

    .e-mobile-nav-open-btn {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      @include standard-padding(false, true, false, true);

      .e-line-wrap {
        width: 24px;
      }

      .e-line {
        background-color: $blue;
        height: 2px;
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
          width: 16px;
        }
      }

    }

    .e-logo-link {
      margin: auto;
      line-height: 0;

      &.desktop {
        display: block;
        margin-right: 40px;
        margin-left: 0;

        @include responsive($tablet) {
          margin-right: 20px;
        }
        @include responsive($tablet-landscape) {
          display: none;
        }
      }

      &.mobile {
        display: none;

        @include responsive($tablet-landscape) {
          display: block;
        }
      }

      .e-logo {
        height: 31px;
        max-width: initial;
        @include responsive($tablet) {
          height: 24px;
        }
      }

    }

    .e-left,
    .e-right {
      display: flex;
      flex-basis: 100%;
      align-items: center;
      height: 100%;
      max-width: 50%;
    }

    .e-left {
      margin: 0 24px 0 0;
      overflow: hidden;

      .e-nav-link {
        flex-shrink: 0;
      }
    }

    .e-right {
      justify-content: flex-end;
      margin: 0 0 0 24px;
      z-index: 2;

      .e-right-inner {
        display: flex;
        align-items: center;
        height: 100%;
      }
    }

    .e-nav-link {
      cursor: pointer;
      color: $blue;
      display: flex;
      align-items: center;
      text-decoration: none;
      line-height: 1;
      margin: 0 30px 0 0;
      height: 100%;
      letter-spacing: 0.02em;
      text-transform: none;
      font-size: 15px;
      line-height: 145%;
      font-weight: 600;

      @include responsive($tablet) {
        font-size: 14px;
        margin: 0 16px 0 0;
      }

      &:hover {
        text-decoration: underline;
      }

      &.m-active {
        color: $red;
      }
    }

    @keyframes slide-down {
      0% {
        display: block;
        opacity: 0;
        top: -100vh;
      }

      100% {
        opacity: 1;
        top: $header_desktop_height;
      }
    }

    @keyframes slide-down-tablet {
      0% {
        display: block;
        opacity: 0;
        top: -100vh;
      }

      100% {
        opacity: 1;
        top: $header_mobile_height;
      }
    }

    @keyframes slide-up {
      0% {
        display: block;
        top: $header_desktop_height;
        opacity: 1;
        @include responsive($tablet) {
          top: $header_mobile_height;
        }
      }

      100% {
        top: -100vh;
        display: none;
        opacity: 0;
      }
    }


    .e-account-links-dropdown {
      background-color: $blue-extra-extra-light;
      display: none;
      max-height: calc(100vh - #{$header_desktop_height});
      opacity: 0;
      overflow-y: scroll;
      padding: 28px 20px 48px;
      width: 366px;
      max-width: 100%;
      z-index: 4;
      position: absolute;
      top: -100vh;
      right: (-$standard-padding);
      @include responsive($tablet) {
        right: 0;
        max-height: calc(100vh - #{$header_mobile_height});
      }
      @include responsive($mobile) {
        max-height: 100vh;
        opacity: 1;
        position: absolute;
        top: $header_mobile_height;
        right: 0;
        bottom: 0;
      }
    }

    .e-account-links-dropdown {
      padding: 24px;
      width: auto;
      right: 0;
      @include responsive($tablet) {
        display: none !important;
      }

      .e-nav-link {
        justify-content: center;
        margin: 0 0 24px 0;

        &:last-child {
          margin: 0;
        }

      }

    }

  }

}
