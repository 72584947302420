:root {
    --animate-duration: 1s;
    --animate-delay: 1s;
    --animate-repeat: 1;
}
@media print, (prefers-reduced-motion: reduce) {
  .animate__animated {
    -webkit-animation-duration: 1ms !important;
    animation-duration: 1ms !important;
    -webkit-transition-duration: 1ms !important;
    transition-duration: 1ms !important;
    -webkit-animation-iteration-count: 1 !important;
    animation-iteration-count: 1 !important;
  }
  .animate__animated[class*='Out'] {
    opacity: 0;
  }
}
.animate__animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-duration: var(--animate-duration);
    animation-duration: var(--animate-duration);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.animate__animated.animate__infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.animate__animated.animate__delay-01s {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
}
.animate__animated.animate__delay-02s {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}
.animate__animated.animate__delay-03s {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
}
.animate__animated.animate__delay-04s {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
}
.animate__animated.animate__delay-05s {
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
}
.animate__animated.animate__delay-06s {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
}
.animate__animated.animate__delay-07s {
    -webkit-animation-delay: 0.7s;
    animation-delay: 0.7s;
}
.animate__animated.animate__delay-08s {
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
}
.animate__animated.animate__delay-09s {
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
}
.animate__animated.animate__delay-1-1s {
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}
.animate__animated.animate__delay-1-2s {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}
.animate__animated.animate__delay-1-3s {
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s;
}
.animate__animated.animate__delay-1-4s {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}
.animate__animated.animate__delay-1-5s {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}
.animate__animated.animate__delay-1-6s {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
}
.animate__animated.animate__delay-1-7s {
  -webkit-animation-delay: 1.7s;
  animation-delay: 1.7s;
}
.animate__animated.animate__delay-2-5s {
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
}
.animate__animated.animate__delay-1s {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-animation-delay: var(--animate-delay);
    animation-delay: var(--animate-delay);
}
.animate__animated.animate__delay-2s {
    -webkit-animation-delay: calc(1s * 2);
    animation-delay: calc(1s * 2);
    -webkit-animation-delay: calc(var(--animate-delay) * 2);
    animation-delay: calc(var(--animate-delay) * 2);
}
.animate__animated.animate__delay-3s {
    -webkit-animation-delay: calc(1s * 3);
    animation-delay: calc(1s * 3);
    -webkit-animation-delay: calc(var(--animate-delay) * 3);
    animation-delay: calc(var(--animate-delay) * 3);
}
.animate__animated.animate__delay-4s {
    -webkit-animation-delay: calc(1s * 4);
    animation-delay: calc(1s * 4);
    -webkit-animation-delay: calc(var(--animate-delay) * 4);
    animation-delay: calc(var(--animate-delay) * 4);
}
.animate__animated.animate__delay-5s {
    -webkit-animation-delay: calc(1s * 5);
    animation-delay: calc(1s * 5);
    -webkit-animation-delay: calc(var(--animate-delay) * 5);
    animation-delay: calc(var(--animate-delay) * 5);
}
@-webkit-keyframes fadeInUpHome {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 10%, 0);
        transform: translate3d(0, 10%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
  }
@keyframes fadeInUpHome {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 10%, 0);
        transform: translate3d(0, 10%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
.animate__fadeInUp-home {
    -webkit-animation-name: fadeInUpHome;
    animation-name: fadeInUpHome;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.animate__fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeOutDown {
	0% {
		opacity: 1
	}

	to {
		opacity: 0;
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0)
	}
}

@keyframes fadeOutDown {
	0% {
		opacity: 1
	}

	to {
		opacity: 0;
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0)
	}
}

.animate__fadeOutDown {
	-webkit-animation-name: fadeOutDown;
	animation-name: fadeOutDown
}
@-webkit-keyframes fadeOutLeft {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  @keyframes fadeOutLeft {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  .animate__fadeOutLeft {
    -webkit-animation-name: fadeOutLeft;
    animation-name: fadeOutLeft;
  }

  @-webkit-keyframes fadeInRight {
    from {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  @keyframes fadeInRight {
    from {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  .animate__fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
  }

  @-webkit-keyframes fadeInTopLeftDiagonal {
    from {
      opacity: 0;
      -webkit-transform: translate3d(-40%, -50%, 0);
      transform: translate3d(-40%, -50%, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  @keyframes fadeInTopLeftDiagonal {
    from {
      opacity: 0;
      -webkit-transform: translate3d(-40%, -50%, 0);
      transform: translate3d(-40%, -50%, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  .animate__fadeInTopLeftDiagonal {
    -webkit-animation-name: fadeInTopLeftDiagonal;
    animation-name: fadeInTopLeftDiagonal;
  }

  @-webkit-keyframes fadeInBottomLeftDiagonal {
    from {
      opacity: 0;
      -webkit-transform: translate3d(-40%, 50%, 0);
      transform: translate3d(-40%, 50%, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  @keyframes fadeInBottomLeftDiagonal {
    from {
      opacity: 0;
      -webkit-transform: translate3d(-40%, 50%, 0);
      transform: translate3d(-40%, 50%, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  .animate__fadeInBottomLeftDiagonal {
    -webkit-animation-name: fadeInBottomLeftDiagonal;
    animation-name: fadeInBottomLeftDiagonal;
  }

  @-webkit-keyframes fadeInBottomRightDiagonal {
    from {
      opacity: 0;
      -webkit-transform: translate3d(40%, 50%, 0);
      transform: translate3d(40%, 50%, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  @keyframes fadeInBottomRightDiagonal {
    from {
      opacity: 0;
      -webkit-transform: translate3d(40%, 50%, 0);
      transform: translate3d(40%, 50%, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  .animate__fadeInBottomRightDiagonal {
    -webkit-animation-name: fadeInBottomRightDiagonal;
    animation-name: fadeInBottomRightDiagonal;
  }

  @keyframes zoomIn {
    from {
      opacity: 0;
      -webkit-transform: scale3d(0.3, 0.3, 0.3);
      transform: scale3d(0.3, 0.3, 0.3);
    }

    50% {
      opacity: 1;
    }
  }
  .animate__zoomIn {
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
  .animate__fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
  }

  @keyframes zoomIn90 {
    from {
      opacity: 1;
      -webkit-transform: scale3d(0.95, 0.95, 0.95);
      transform: scale3d(0.95, 0.95, 0.95);
    }

    50% {
      opacity: 1;
    }
  }
  .animate__zoomIn95 {
    -webkit-animation-name: zoomIn90;
    animation-name: zoomIn90;
  }

  @keyframes fadeInUpSmall {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 1.5%, 0);
        transform: translate3d(0, 1.5%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
.animate__fadeInUp-small {
    -webkit-animation-name: fadeInUpSmall;
    animation-name: fadeInUpSmall;
}


@keyframes zoomOutToIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  50% {
    opacity: 1;
  }
}
.animate__zoomOutToIn {
  -webkit-animation-name: zoomOutToIn;
  animation-name: zoomOutToIn;
}

@keyframes zoomOutToInSmall {
  from {
    opacity: 1;
    -webkit-transform: scale3d(1.02, 1.02, 1.02);
    transform: scale3d(1.02, 1.02, 1.02);
  }

  50% {
    opacity: 1;
  }
}
.animate__zoomOutToInSmall {
  -webkit-animation-name: zoomOutToInSmall;
  animation-name: zoomOutToInSmall;
}

@media screen and (min-width: 900px) {
  @keyframes fadeInUpBrainHome {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 10%, 0) translateY(-50%) translateX(-50%);
      transform: translate3d(0, 10%, 0) translateY(-50%) translateX(-50%);
  }

  to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0) translateY(-50%) translateX(-50%);
      transform: translate3d(0, 0, 0) translateY(-50%) translateX(-50%);
  }
  }
}
.animate__fadeInUpBrain-home {
  -webkit-animation-name: fadeInUpBrainHome;
  animation-name: fadeInUpBrainHome;
}

@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

@-webkit-keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
@keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
.animate__slideOutRight {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight;
}

.animate__slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.animate__slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.animate__slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
  -webkit-animation-duration: 600ms !important;
  animation-duration: 600ms !important;
  -webkit-transition-duration: 600ms !important;
  transition-duration: 600ms !important;
}
@-webkit-keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
@keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
.animate__slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown;
  -webkit-animation-duration: 600ms !important;
  animation-duration: 600ms !important;
  -webkit-transition-duration: 600ms !important;
  transition-duration: 600ms !important;
}
@-webkit-keyframes slideOutRight30 {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(130%, 0, 0);
    transform: translate3d(130%, 0, 0);
  }
}
@keyframes slideOutRight30 {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(130%, 0, 0);
    transform: translate3d(130%, 0, 0);
  }
}
.animate__slideOutRight30 {
  -webkit-animation-name: slideOutRight30;
  animation-name: slideOutRight30;
}

@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.animate__slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
}

@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}


@keyframes moveRight {
  from {
      margin-right: -126px,
  }
  to {
      margin-right: -1098px,
  }
}

@-webkit-keyframes moveRight {
  from {
      margin-right: -126px,
  }
  to {
      margin-right: -1098px,
  }
}

.animation-move-right {
  -webkit-animation-name: moveRight;
  animation-name: moveRight;
}


@-webkit-keyframes width100 {
  from {
    opacity: 1;
    width: '50%';
    margin-bottom: 125px;
  }
  to {
    opacity: 1;
    width: 1131px;
    margin-bottom: 125px;
  }
}

@keyframes width100 {
  from {
    opacity: 1;
    width: '50%';
    margin-bottom: 125px;
  }
  to {
    opacity: 1;
    width: 1131px;
    margin-bottom: 125px;
  }
}

.animation-width100 {
  -webkit-animation-name: width100;
  animation-name: width100;
}

@-webkit-keyframes rangeMonthsDesktop {
  0% {
    opacity: 1;
    left: 50px;
    }
    50% {
        opacity: 1;
        left: 38px;
    }
    100% {
      opacity: 1;
      left: 50px;
    }
}
@keyframes rangeMonthsDesktop {
  0% {
        opacity: 1;
        left: 50px;
    }
    50% {
        opacity: 1;
        left: 38px;
    }
    100% {
      opacity: 1;
      left: 50px;
  }
}

.range-months.desktop {
  -webkit-animation-name: rangeMonthsDesktop;
  animation-name: rangeMonthsDesktop;
  animation-duration: 1.7s;
}

@-webkit-keyframes rangeMonthsMobile {
  0% {
    opacity: 1;
    bottom: 50px;
  }
  50% {
    opacity: 1;
    bottom: 35px;
  }
  100% {
    opacity: 1;
    bottom: 50px;
  }
}
@keyframes rangeMonthsMobile {
  0% {
    opacity: 1;
    bottom: 50px;
  }
  50% {
    opacity: 1;
    bottom: 35px;
  }
  100% {
    opacity: 1;
    bottom: 50px;
  }
}

.range-months.mobile {
  -webkit-animation-name: rangeMonthsMobile;
  animation-name: rangeMonthsMobile;
  animation-duration: 1.7s;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.animate__fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}


@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.animate__fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}
@keyframes motion-blur {
  0%
  {
    left: 0;
    box-shadow: 0 0 0 rgba(255,255,255,0);
  }
  5%
  {
    left: 0;
    transform-origin: 0 0;
    transform: scaleX(0.85);
  }
  25%
  {
    box-shadow: -20px 0 15px -10px rgba(255,255,255,0.4);
    transform: scaleX(1.05) skewX(-3deg);
  }
  50%
  {
    left: 300px;
    box-shadow: 0 0 0 rgba(255,255,255,0);
    transform: scaleX(1) skewX(0deg);
  }
  55%
  {
    left: 300px;
    transform-origin: 100% 0;
    transform: scaleX(0.9);
  }
  75%
  {
    box-shadow: 20px 0 15px -10px rgba(255,255,255, 0.4);
    transform: scaleX(1.05) skewX(3deg);
  }
  100%
  {
    left: 0px;
    box-shadow: 0 0 0 rgba(255,255,255,0);
    transform: scaleX(1) skewX(0deg);
  }
}