$grid-breakpoints: (
    xs: 0,
    sm: 600px,
    md: 900px,
    lg: 1280px,
    xl: 1800px,
);

$container-max-widths: (
    sm: 564px,
    md: 852px,
    lg: 978px,
    xl: 978px,
);

$spacer: 0.8rem;
$spacers: (0:0);
@for $i from 1 through 10 {
  $spacers: map-merge($spacers, (
  $i: ($i * $spacer)
  ));
}

INPUT, SELECT, TEXTAREA {
  &:-webkit-autofill {
    animation-name: onautofillstart;
  }

  &:not(:-webkit-autofill) {
    animation-name: onautofillcancel;
  }
}

@keyframes onautofillstart { from {} }
@keyframes onautofillcancel { from {} }