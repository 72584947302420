@import 'vendor/normalize';

@import 'env';
@import 'header';
@import 'date_input';

#coop-modal-button {
  bottom: 25px !important;
  right: 190px !important;
}

.sequence {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  padding: 0px;
  margin: 0px;
  justify-content: center;
  align-items: center;
  background-color: #fff;

  &.active {
    opacity: 1;

    & > * {
      pointer-events: all;
    }
  }

  .subslide {
    height: 100%;
  }
}

#slide-1-4 {
  .page-container: {
    height: auto !important;
  }
}

#gorgias-chat-container {
  z-index: 499;
}

// follow
// https://use.typekit.net/uka0won.css

@import url("https://p.typekit.net/p.css?s=1&k=uka0won&ht=tk&f=24537.24539.24545.24546.24549&a=22977307&app=typekit&e=css");

@font-face {
  font-family:"sofia-pro";
  src:url("https://use.typekit.net/af/0c5f71/00000000000000003b9b1aa0/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff2"),url("https://use.typekit.net/af/0c5f71/00000000000000003b9b1aa0/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff"),url("https://use.typekit.net/af/0c5f71/00000000000000003b9b1aa0/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("opentype");
  font-display:swap;font-style:normal;font-weight:900;
}

@font-face {
font-family:"sofia-pro";
  src:url("https://use.typekit.net/af/30420e/00000000000000003b9b1a9e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/30420e/00000000000000003b9b1a9e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/30420e/00000000000000003b9b1a9e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display:swap;font-style:normal;font-weight:700;
}

@font-face {
  font-family:"sofia-pro";
  src:url("https://use.typekit.net/af/b4d13d/00000000000000003b9b1a9a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/b4d13d/00000000000000003b9b1a9a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/b4d13d/00000000000000003b9b1a9a/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
  font-display:swap;font-style:normal;font-weight:500;
}

@font-face {
  font-family:"sofia-pro";
  src:url("https://use.typekit.net/af/961cbb/00000000000000003b9b1a99/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("woff2"),url("https://use.typekit.net/af/961cbb/00000000000000003b9b1a99/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("woff"),url("https://use.typekit.net/af/961cbb/00000000000000003b9b1a99/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("opentype");
  font-display:swap;font-style:italic;font-weight:500;
}

@font-face {
  font-family:"sofia-pro";
  src:url("https://use.typekit.net/af/7158ff/00000000000000003b9b1a9c/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/7158ff/00000000000000003b9b1a9c/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"),url("https://use.typekit.net/af/7158ff/00000000000000003b9b1a9c/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
  font-display:swap;font-style:normal;font-weight:600;
}

.tk-sofia-pro { font-family: "sofia-pro",sans-serif; }

html {
  box-sizing: border-box;
  font-size: 62.5%;
  margin-top: 0 !important;
  -webkit-font-smoothing: antialiased;
}

* {
  font-family: $sofia;
  touch-action: pan-x pan-y pinch-zoom;
}

*:focus {
  // outline: none !important;
}

body {
  background-color: $white;
  display: flex;
  line-height: 100%;
  height: 100vh;
  touch-action: pan-x pan-y pinch-zoom;

  &.m-overflow-hidden {
    overflow: hidden;
  }

  &.m-overflow-hidden-nav {
    overflow: hidden;
    @media screen and (min-width: 900px){
      overflow: unset;
    }
  }

  &.m-1, &.m-2, &.m-3, &.m-4, &.m-5, &.m-6, &.m-7, &.m-8 {
    margin: 0px !important;
  }
}
/* Reck Pack Page styles for the fullpage.js
 - styling the dots on active/hover/non active state
 - used @media (max-width) as I had to use !important,
 - otherwise the screens on mobile are getting for example a height of 350px
 - last but not least: I KINDA HATE THAT PLUGIN! */
body {
  #fp-nav ul li a span,
  #fp-nav ul li a.active span {
    width: 8px !important;
    height: 8px !important;
    background: $white !important;
  }
  #fp-nav ul li a.active span {
    width: 8px !important;
    height: 8px !important;
    opacity: 1;
    margin: -2px 0 0 -2px !important;
  }
  #fp-nav ul li a span {
    opacity: 0.4;
  }
  #fp-nav ul li:hover a span, .fp-slidesNav ul li:hover a span {
    width: 8px;
    height: 8px;
    margin: -2px 0 0 -2px;
    opacity: 1;
  }
  .fp-section {
    @media screen and (max-width: 900px){
      height: auto !important;
    }
  }
}

*, *:before, *:after {
  box-sizing: inherit;
}

p {
  @include paragraph;
}

ul {
  padding: 0;
  margin: 0;
}

input {
  &:focus {
    outline: none;
  }
}

.e-main-container {
  color: $navy;
  width: 100%;
  @include paragraph-text;

  .e-page-content-wrap {
    max-width: 100%;

    .content-spacer {
      @media screen and (min-width: 900px){
        margin-top: $header_desktop_height;
        display: block;
      }
    }

    // Default page component wrapper styling. Exceptions should be handled in the component style file.
    > div:not(.modal) {
      flex: 1;
      max-width: 100%;

      // margin-top: $header_desktop_height;
      @include standard-padding(true, true, true, true);
    }

    .top-homepage {
      @media screen and (min-width: 900px){
        top: 60px;
      }
    }
  }

  .dropdown {
    top: 50px;
    height: 100%;
    overflow-y: auto;
    @media screen and (min-width: 900px){
      top: 60px;
      height: 281px;
      min-height: unset;
      overflow-y: unset;
    }
  }
  .page-home {
      #about {
          height: calc(100vh - 110px);
      }
  }

  &.with-promo, &.with-oos {
    &.is-not-logged {
      #sticky {
        @include responsive($tablet) {
          margin-top: $header_tablet_height;
        }

        @include responsive(900px) {
          margin-top: $header_mobile_height;
        }
      }
    }

    .homepage-video-wrapper {
      height: calc(100vh - 108px);
    }

    .page-home {
        #about {
            height: calc(100vh - 110px);
        }
    }

    .e-page-content-wrap {
      // For promo banner in box builder
      .header-progress-wrap {
        @media screen and (max-width: 600px){
            top: 100px;
        }
      }
      .boxmenuContainer {
        @media screen and (max-width: 900px){
          top: 96px !important;
        }

        .sidebar {
          @media screen and (min-width: 900px){
            top: 180px !important;
          }
        }
      }
      .boxmenuScroll {
        @media screen and (max-width: 900px){
          top: 30px !important;
        }
      }
      .pageContainer > .container > div.element-filter {
          @media screen and (max-width: 600px){
            top: 120px !important;
          }
          @media screen and (min-width: 600px) {
            top: 100px !important;
          }
          @media screen and (min-width: 900px) {
            top: 109px !important;
          }
      }
      .sidemenuContainer {
        top: 100px !important ;
      }
    }
    .top-homepage {
      @media screen and (min-width: 900px){
        top: 110px;
      }
    }
    .dropdown {
      top: 100px;
      height: 100%;
      overflow-y: auto;
      @media screen and (min-width: 900px){
        top: 110px;
        height: 281px;
        min-height: unset;
        overflow-y: unset;
      }
    }
    .b-header {
      &::before {
        content: "";
        display: block;
        height: 50px;
        z-index: 1;
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        background: $blue;
      }
    }
  }
}

.with-oos {
  .b-header {
    &:before {
      display: none !important;
    }
  }
  .e-page-content-wrap {
    padding-top: 98px !important;

    @media screen and (min-width: 900px){
      padding-top: 110px !important;

    }
    @media screen and (max-width: 900px){
      padding-top: 100px !important;
    }
  }
}

.e-page-content {
  &.with-promo, &.with-oos {
    .page-home {
        #about {
            height: calc(100vh - 110px);
        }
    }

    .e-page-content-wrap {
      padding-top: 48px;

      @media screen and (min-width: 900px){
        padding-top: 60px;
      }
      @media screen and (max-width: 900px){
        padding-top: 50px;
      }
    }
  }

  &.is-not-logged {
    .e-page-content-wrap {
      padding-top: 98px;

      @media screen and (min-width: 900px){
        padding-top: 110px;
      }
      @media screen and (max-width: 900px){
        padding-top: 100px;
      }
    }
  }
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &.m-left,
  &.m-right,
  &.m-up,
  &.m-down,
  &.m-plus {
    padding-right: 0.8em;
    position: relative;
    text-decoration: none;
  }

  &.m-left:after,
  &.m-right:after,
  &.m-up:after,
  &.m-down:after {
    border-top: 0.1em solid $red;
    border-right: 0.1em solid $red;
    box-sizing: border-box;
    content: " ";
    height: 0.4em;
    position: absolute;
    width: 0.4em;
  }

  &.m-left {
    padding-left: 0.65em;
    &:after {
      left: 0;
      top: calc(50% - 0.15em);
      transform: rotate(-135deg);
    }
  }

  &.m-right {
    padding-right: 0.65em;
    &:after {
      right: 0;
      top: calc(50% - 0.15em);
      transform: rotate(45deg);
    }

  }

  &.m-down:after {
    right: 0;
    top: calc(50% - 0.25em);
    transform: rotate(135deg);
  }

  &.m-up:after {
    right: 0;
    top: calc(50% - 0.05em);
    transform: rotate(-45deg);
  }

  &.m-plus {
    &:before {
      background: $red;
      content: " ";
      height: 0.5em;
      position: absolute;
      right: 0.2em;
      top: calc(50% - 0.2em);
      width: 0.1em;
    }
    &:after {
      background: $red;
      content: " ";
      height: 0.1em;
      position: absolute;
      right: 0;
      top: 50%;
      width: 0.5em;
    }
  }

}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

h1 {
  @include h1-text;
}

h2 {
  @include h2-text;
}

h3 {
  @include h3-text;
}

h4 {
  @include h4-text;
}

h5 {
  @include h5-text;
}

h6 {
  @include h6-text;
}

// builder.io styling
.builder-component {
    margin-top: 0px !important;
    padding-top: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;

    .builder-block {
      display: contents;
    }
}

.wp-block-cover img.wp-block-cover__image-background {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  max-width: none;
  max-height: none;
  -o-object-fit: cover;
     object-fit: cover;
  outline: none;
  border: none;
  box-shadow: none;
}

.wp-block-cover__inner-container {
  color: #fff;
  z-index: 1;
}

// stamped.io
.stamped-review-avatar {
  text-shadow: 0 0 rgb(255, 255, 255) !important;
}
.stamped-reviews-search-text input.stamped-reviews-search-input, .stamped-questions-search-text input.stamped-questions-search-input {
  padding-left: 27px !important;
}
.stamped-review-content-body{
  font-weight: 400 !important;
}
#stamped-sort-select, #stamped-sort-select2{
  margin-top: 15px !important;
}
.summary-overview{
  @include responsive($mobile) {
    width: 200px !important;
    margin: auto;
  }
}
#stamped-sort-select{
  border: solid 1px #d5d9e8 !important;
}

.e-faq-title {
  background-color: $blue-extra-extra-light;
  text-align: center;
  margin: 0 0 50px;
  padding-top: 80px;
  padding-bottom: 88px;

  @include h1-text;
  @include standard-padding(false, true, false, true);

  @include responsive($tablet) {
    padding-top: 48px;
    padding-bottom: 54px;
  }
}

@import '@v2/styles/bootstrap.scss';
