@mixin responsive($breakpoint) {
  @media (max-width: $breakpoint) {
    @content;
  }
}

@mixin page-title {
  font-size: 68px;
  font-weight: 700;
  line-height: 100%;

  @include responsive($laptop) {
    font-size: 56px;
  }
  @include responsive($tablet) {
    font-size: 52px;
  }
  @include responsive($mobile) {
    font-size: 42px;
  }
}

@mixin h1-text {
  font-size: 44px;
  line-height: 115%;
  font-weight: 900;

  @include responsive($laptop) {
    font-size: 38px;
  }
  @include responsive($tablet) {
    font-size: 28px;
  }
  @include responsive($mobile) {
    font-size: 32px;
  }
}

@mixin h1-header {
  @include h1-text;
  display: block;
  margin-bottom: 40px;

  @include responsive($laptop) {
    margin-bottom: 32px;
  }
  @include responsive($tablet) {
    margin-bottom: 24px;
  }
}

@mixin h2-text {
  font-size: 38px;
  line-height: 110%;
  font-weight: 700;
  letter-spacing: 0;

  @include responsive($laptop) {
    font-size: 30px;
  }
  @include responsive($tablet) {
    font-size: 24px;
  }
}

@mixin h2-header {
  @include h2-text;
  display: block;
  font-weight: 900;
  margin-bottom: 35px;

  @include responsive($laptop) {
    margin-bottom: 28px;
  }
  @include responsive($tablet) {
    margin-bottom: 22px;
  }
}

@mixin h3-2-text {
  font-size: 28px;
  line-height: 145%;
  font-weight: 700;

  @include responsive($tablet) {
    font-size: 22px;
  }
}

@mixin h3-text {
  font-size: 24px;
  line-height: 115%;
  font-weight: 700;

  @include responsive($laptop) {
    font-size: 19px;
  }
  @include responsive($tablet) {
    font-size: 18px;
  }
}

@mixin h4-text {
  font-size: 20px;
  line-height: 130%;
  font-weight: 700;

  @include responsive($laptop) {
    font-size: 16px;
  }
}

@mixin h5-text {
  font-size: 18px;
  line-height: 150%;
  font-weight: 700;
  letter-spacing: 0.25em;
  text-transform: uppercase;

  @include responsive($tablet) {
    font-size: 16px;
  }
}

@mixin h6-text {
  font-size: 14px;
  line-height: 150%;
  font-weight: 500;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  @include responsive($laptop) {
    font-size: 13px;
  }
  @include responsive($tablet) {
    font-size: 11px;
  }
  @include responsive($mobile) {
    font-size: 13px;
  }
}


@mixin paragraph-text {
  font-size: 20px;
  line-height: 145%;
  font-weight: 600;
  letter-spacing: 0;

  @include responsive($laptop) {
    font-size: 16x;
  }
  @include responsive($tablet-vertical) {
    font-size: 13x;
  }
}

@mixin paragraph {
  margin-top: 0;
  margin-bottom: 30px;
  @include paragraph-text;
  @include responsive($tablet) {
    margin-bottom: 20px;
  }
}

@mixin content-paragraph-text {
  font-size: 27px;
  line-height: 145%;
  font-weight: 400;
  @include responsive($mobile) {
    font-size: 14px;
    font-size: 14px;
  }
}

@mixin content-paragraph {

  margin-top: 0;
  margin-bottom: 30px;
  @include content-paragraph-text;
  @include responsive($tablet) {
    margin-bottom: 20px;
  }
}

@mixin content-hero-text {
  font-size: 24px;
  line-height: 145%;
  font-weight: 400;

  @include responsive($tablet) {
    font-size: 20px;
  }
}

@mixin content-hero-paragraph {
  margin-top: 0;
  margin-bottom: 40px;

  @include content-hero-text;
  @include responsive($tablet) {
    margin-bottom: 30px;
  }
}

@mixin small-text {
  font-size: 16px;
  line-height: 115%;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: none;

  @include responsive($laptop) {
    font-size: 14px;
  }
  @include responsive($tablet) {
    font-size: 13px;
  }
  @include responsive($mobile) {
    font-size: 14px;
  }
}

@mixin extra-small-text {
  letter-spacing: 0;
  text-transform: none;
  font-size: 14px;
  @include responsive($tablet) {
    font-size: 13px;
  }
  @include responsive($mobile) {
    font-size: 14px;
  }
}

@mixin tiny-text {
  letter-spacing: 0;
  text-transform: none;
  font-size: 11px;
  @include responsive($tablet) {
    font-size: 10px;
  }
  @include responsive($mobile) {
    font-size: 11px;
  }
}


// The styles for the stripe fields are in StripeCheckoutForm, function: get_stripe_device_style
@mixin checkout-text {
  font-size: 20px;
  line-height: 145%;
  font-weight: 500;

  @include responsive($mobile) {
    font-size: 16px;
  }
  @include responsive($mobile-small) {
    font-size: 14px;
  }
}

@mixin checkout-checkbox-btn-text {
  font-size: 16px;
  line-height: 115%;
  font-weight: 700;

  @include responsive($tablet) {
    font-size: 14px !important;
  }
  @include responsive($checkout-mobile-medium) {
    font-size: 10px !important;
  }
}

@mixin checkout-section-nav-text {
  font-size: 16px;
  line-height: 115%;
font-weight: 700;

  @include responsive($mobile) {
    font-size: 12px;
  }
  @include responsive($mobile-small) {
    font-size: 10px;
  }
}

@mixin checkout-error-text {
  font-size: 16px;
  line-height: 115%;
  font-weight: 500;
  @include responsive($mobile) {
    font-size: 14px;
  }
}

@mixin checkout-small-placeholder {
  font-size: 14px;
  @include responsive($mobile-small) {
    font-size: 12px;
  }
}

@mixin checkout-footer-space() {
  $error-height: 56px;
  $space: 170px;
  $mobile-space: 146px;
  padding-bottom: calc(#{$space + $mobile-padding});

  @include responsive($mobile) {
    padding-bottom: $mobile-space;
  }

}

@mixin btn-defaults {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

@mixin blue-square-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $blue;
  border: 0;
  border-radius: 50px;
  color: $white;
  line-height: 145%;
  padding: 10px 24px 10px;
  text-decoration: none;
  text-align: center;
  max-width: 100%;
  border-radius: 5px;
  width: auto;
  min-height: 44px;
  @include extra-small-text;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  &:disabled {
    opacity: 0.5;
  }
}

@mixin red-square-button {
  @include blue-square-button;
  background-color: $red;
  color: $white;
  border: 0;
}

@mixin content-max-width {
  max-width: $desktop;
}

@mixin arrow($direction, $color, $border-width:2px, $border-length:10px) {
  display: block;
  border-top: $border-width solid $color;
  border-right: $border-width solid $color;

  @if $direction == right {
    transform: rotate(45deg);
  } @else if $direction == left {
    transform: rotate(-135deg);
  } @else if $direction == down {
    transform: rotate(135deg);
  } @else if $direction == up {
    transform: rotate(-45deg);
  }

  width: $border-length;
  height: $border-length;
}


@mixin padding($top, $right, $bottom, $left) {
  @if $top {
    padding-top: $top;
  }
  @if $right {
    padding-right: $right;
  }
  @if $bottom {
    padding-bottom: $bottom;
  }
  @if $left {
    padding-left: $left;
  }
}

@mixin standard-padding($top, $right, $bottom, $left) {

  @if $top {
    padding-top: $standard-padding;
    @include responsive($tablet) {
      padding-top: $mobile-padding;
    }
  }

  @if $right {
    padding-right: $standard-padding;
    @include responsive($tablet) {
      padding-right: $mobile-padding;
    }
  }

  @if $bottom {
    padding-bottom: $standard-padding;
    @include responsive($tablet) {
      padding-bottom: $mobile-padding;
    }
  }

  @if $left {
    padding-left: $standard-padding;
    @include responsive($tablet) {
      padding-left: $mobile-padding;
    }
  }
}



@mixin half-screen-block-left($fullwidth:false) {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  order: 1;
  width: 50%;
  @if $fullwidth == true {
    padding: 0 5% 0 calc(#{$standard-padding} + 7%);
  }
  @else {
    padding: 0 5% 0 7%;
  }

  @include responsive($tablet) {
    @if $fullwidth == true {
      padding: 0 3% 0 calc(#{$mobile-padding} + 4%);
    }
    @else {
      padding: 0 3% 0 4%;
    }
  }

  @include responsive($mobile) {
    display: block;
    order: 3;
    width: 100%;
    @if $fullwidth == true {
      padding: $mobile-padding;
    }
    @else {
      padding: $mobile-padding 0;
    }
  }

}

@mixin half-screen-block-right($fullwidth:false) {
  @include half-screen-block-left($fullwidth);
  order: 3;
}


@mixin half-screen-image {
  align-self: center;
  box-sizing: border-box;
  order: 2;
  width: 50%;
  @include responsive($mobile) {
    width: 100%;
  }
}

